import React, {useState, useRef, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import AnalyticsImage from '../../images/analytics.jpeg'
import GTMLogo from '../../images/gtm_image.svg'
import {useAuth} from '../../auth/AuthContext';
import Cookie from 'js-cookie';

export default function LoginPage({children}) {
    const classes = useStyles();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [isRememberMeChecked, setIsRememberMeChecked] = useState(false);
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false)
    const [error, setError] = useState();
    const {login, loginWithGoogle, currentUser} = useAuth();

    useEffect(() => {
        let usernameCookie = Cookie.get('username');
        if(usernameCookie) {
            setUsername(usernameCookie);
            setIsRememberMeChecked(true);
        }
    }, []);

    function handleSignIn() {
        if (isRememberMeChecked) {
            Cookie.set('username', username, {expires: 365})
        }
        else {
            Cookie.remove('username')
        }
        login(username, password)
            .then((user) => {
                console.log(user)
            })
            .catch((error) => {
                setError(error.message);
            })
    };

    function handleSignInWithGoogle() {
        console.log(login)
        loginWithGoogle()
            .then((user) => {
                console.log(user)
            })
            .catch((error) => {
                setError(error.message);
            })
    };

    function handleUsernameChange(e) {
        setUsername(e.target.value)
        updateLoginButton(e.target.value, password)
    }

    function handlePasswordChange(e) {
        setPassword(e.target.value)
        updateLoginButton(username, e.target.value)
    }

    function handleRememberMeChange(e) {
        setIsRememberMeChecked(e.target.checked)
    }
    function updateLoginButton(username, password) {
        setIsSubmitEnabled(username != null && username != "" && password != null && password != "")
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center',height: '100%', padding:20}}>
                    <div style={{display: 'flex', justifyContent: 'center', marginTop: 50}}>
                        <img src={GTMLogo} width={150}/>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        {error ? <Alert severity="error" style={{marginBottom: 20}}>{error}</Alert> : null}
                        <div>
                            <p>This is the CMS login for the Server Side playground site. You will need an @mightyhive.com email address to login and modify the site.</p>
                        </div>
                        <Button style={{marginTop: 30}} variant={'contained'} color={'primary'} onClick={handleSignInWithGoogle}>Login With Google</Button>

                    </div>
                </div>
            </Grid>
        </Grid>
    );
}


const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh'
    },
    image: {
        backgroundImage: `url(${AnalyticsImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    }
}));
