import React, {useEffect, useState} from 'react';
import {EditorState, ContentState, convertToRaw, convertFromHTML} from 'draft-js';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css"
import "./Draft.css"
import Button from "@material-ui/core/Button";
import { convertToHTML } from 'draft-convert';
import {createNewPage, getPageContent, uploadImage} from "../../api/storage";
import {useLocation, Redirect} from 'react-router-dom';
import TextField from "@material-ui/core/TextField";
import {urls} from "../../routes/urls";
import {useToast} from "../../components/ToastContext";

const PagesEditPage = ({history, mode, onCreate}) => {
    const [editorState, setEditorState] = React.useState(() => EditorState.createEmpty());
    const queryParamPageURL = new URLSearchParams(useLocation().search).get('pageUrl')
    const [pageUrl, setPageUrl] = useState("")
    const [pageTitle, setPageTitle] = useState("")
    const {displaySuccessToast} = useToast();
    useEffect(async () => {
        if(mode == 'edit') {
            let content = await getPageContent(queryParamPageURL)
            console.log(content.contentBody)
            let editorContent = convertFromHTML(content.contentBody)
            console.log("Editor Content")
            console.log(editorContent)
            let contentState = ContentState.createFromBlockArray(
                editorContent.contentBlocks,
                editorContent.entityMap,
            );
            setPageUrl(queryParamPageURL)
            setPageTitle(content.title)
            setEditorState(EditorState.createWithContent(contentState))
        }
    }, []);

    async function handleSavePage() {
        let content = convertToHTML({
            entityToHTML: (entity, originalText) => {
                console.log(entity)
                console.log(originalText)
                if (entity.type === "IMAGE") {
                    console.log("Converting Image")
                    return `<img src=\'${entity.data["src"]}\' height=\'300px\' width=\'${entity.data["width"]}\' alt=\'${entity.data["alt"]}\'/>`;
                }

                return originalText;
            },
        })(editorState.getCurrentContent())
        console.log("Converting TO Raw")
        console.log(convertToRaw(editorState.getCurrentContent()))

        await createNewPage(pageUrl, pageTitle, content)
        displaySuccessToast(`${mode == 'edit' ? 'Saved' : 'Created'} Successfully`)
        if(onCreate != null){ onCreate({pageUrl: pageUrl})}
        history.push(urls.content.pages.main)
    }

    function handleCancel() {
        history.push(urls.content.pages.main)
    }

    async function uploadImageCallBack(file) {
        return uploadImage(file)
    }
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <h1>{mode == 'edit' ? 'Edit Content' : ' Create New Page'}</h1>
            <TextField
                id="outlined-basic"
                label="Page URL"
                variant="outlined"
                style={{marginBottom: 20}}
                placeholder={"/example/page"}
                value={pageUrl}
                onChange={(e) => setPageUrl(e.target.value)}
                disabled={mode == 'edit'}
            />
            <TextField
                id="outlined-basic"
                label="Page Title"
                variant="outlined"
                style={{marginBottom: 20}}
                placeholder={"Example Page"}
                value={pageTitle}
                onChange={(e) => setPageTitle(e.target.value)}
            />
            <Editor
                editorState={editorState}
                toolbarClassName="toolbarClassName"
                wrapperClassName="editor-wrapper"
                editorClassName="editor-container"
                onEditorStateChange={(state) => {setEditorState(state)}}
                toolbar={{
                    options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'emoji', 'image', 'remove', 'history'],
                    inline: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['bold', 'italic', 'underline', 'strikethrough', 'monospace', 'superscript', 'subscript'],
                    },
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote', 'Code'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    fontFamily: {
                        options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    },
                    list: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['unordered', 'ordered', 'indent', 'outdent'],
                    },
                    textAlign: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                        options: ['left', 'center', 'right', 'justify'],
                    },
                    colorPicker: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        colors: ['rgb(97,189,109)', 'rgb(26,188,156)', 'rgb(84,172,210)', 'rgb(44,130,201)',
                            'rgb(147,101,184)', 'rgb(71,85,119)', 'rgb(204,204,204)', 'rgb(65,168,95)', 'rgb(0,168,133)',
                            'rgb(61,142,185)', 'rgb(41,105,176)', 'rgb(85,57,130)', 'rgb(40,50,78)', 'rgb(0,0,0)',
                            'rgb(247,218,100)', 'rgb(251,160,38)', 'rgb(235,107,86)', 'rgb(226,80,65)', 'rgb(163,143,132)',
                            'rgb(239,239,239)', 'rgb(255,255,255)', 'rgb(250,197,28)', 'rgb(243,121,52)', 'rgb(209,72,65)',
                            'rgb(184,49,47)', 'rgb(124,112,107)', 'rgb(209,213,216)'],
                    },
                    link: {
                        inDropdown: false,
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        dropdownClassName: undefined,
                        showOpenOptionOnHover: true,
                        defaultTargetOption: '_self',
                        options: ['link', 'unlink'],
                        linkCallback: undefined
                    },
                    emoji: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        emojis: [
                            '😀', '😁', '😂', '😃', '😉', '😋', '😎', '😍', '😗', '🤗', '🤔', '😣', '😫', '😴', '😌', '🤓',
                            '😛', '😜', '😠', '😇', '😷', '😈', '👻', '😺', '😸', '😹', '😻', '😼', '😽', '🙀', '🙈',
                            '🙉', '🙊', '👼', '👮', '🕵', '💂', '👳', '🎅', '👸', '👰', '👲', '🙍', '🙇', '🚶', '🏃', '💃',
                            '⛷', '🏂', '🏌', '🏄', '🚣', '🏊', '⛹', '🏋', '🚴', '👫', '💪', '👈', '👉', '👉', '👆', '🖕',
                            '👇', '🖖', '🤘', '🖐', '👌', '👍', '👎', '✊', '👊', '👏', '🙌', '🙏', '🐵', '🐶', '🐇', '🐥',
                            '🐸', '🐌', '🐛', '🐜', '🐝', '🍉', '🍄', '🍔', '🍤', '🍨', '🍪', '🎂', '🍰', '🍾', '🍷', '🍸',
                            '🍺', '🌍', '🚑', '⏰', '🌙', '🌝', '🌞', '⭐', '🌟', '🌠', '🌨', '🌩', '⛄', '🔥', '🎄', '🎈',
                            '🎉', '🎊', '🎁', '🎗', '🏀', '🏈', '🎲', '🔇', '🔈', '📣', '🔔', '🎵', '🎷', '💰', '🖊', '📅',
                            '✅', '❎', '💯',
                        ],
                    },
                    embedded: {
                        className: undefined,
                        component: undefined,
                        popupClassName: undefined,
                        embedCallback: undefined,
                        defaultSize: {
                            height: 'auto',
                            width: 'auto',
                        },
                    },
                    image: { uploadCallback: uploadImageCallBack,
                        alt: { present: true, mandatory: false }, }
                }}
                placeholder={'Enter your content here'}
            />
            <div style={{display: 'flex', justifyContent:'flex-end'}}>
                <Button variant={'contained'} color={'primary'} style={{marginTop: 20, marginRight:20 }} onClick={handleSavePage}>{mode == 'edit' ? 'Save' : 'Create'}</Button>
                <Button variant={'contained'} color={'primary'} style={{marginTop: 20}} onClick={handleCancel}>Cancel</Button>
            </div>

        </div>
    );
};

export default PagesEditPage;