import React from 'react';
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {Box, Drawer, makeStyles} from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import {NavLink} from 'react-router-dom'
import {urls} from "../routes/urls";
import {useAuth} from "../auth/AuthContext";
import Button from "@material-ui/core/Button";
const drawerWidth = 300;
const useStyles = makeStyles((theme) => {
    return {
    root: {
        display: 'flex'
    },
    appBar: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
    },
    activeLink: {
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        color: 'white'
    }
}});

function ListItemLink(props) {
    const { icon, primary, to, exact} = props;
    const classes = useStyles();
    const CustomLink = React.useMemo(
        () =>
            React.forwardRef((linkProps, ref) => (
                <NavLink activeClassName={classes.activeLink} ref={ref} to={to} exact={exact} {...linkProps} />
            )),
        [to],
    );

    return (
        <li>
            <ListItem button component={CustomLink}>
                <ListItemIcon style={{color: 'inherit'}}>{icon}</ListItemIcon>
                <ListItemText primary={primary}/>
            </ListItem>
        </li>
    );
}

const AuthenticatedLayout = (props) => {
    const classes = useStyles();
    const {currentUser, logout} = useAuth();
    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar style={{justifyContent: 'space-between'}}>
                    {props.actionBar}
                    <Button variant={'contained'} onClick={() => logout()}>Logout</Button>
                </Toolbar>
            </AppBar>
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <div className={classes.toolbar} >
                    <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'} style={{height: "100%", padding: 5}}>
                        <Avatar src={currentUser.photoURL} style={{marginRight:10}}/>
                        <Box display={'flex'} flexDirection={'column'}>
                            <Typography variant={'h5'} style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', margin: 0, textAlign: 'left'}}>{currentUser.displayName}</Typography>
                            <Typography component={'p'} style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', margin: 0, textAlign: 'left'}}><small>{currentUser.email}</small></Typography>
                        </Box>
                    </Box>
                </div>
                {linkSections.map((section, sectionIndex) => {
                    return(
                        <div key={`menu-list-section-${sectionIndex}`}>
                            <Divider />
                            <List>
                                <ListSubheader>{section.title}</ListSubheader>
                                {section.links.map((link, linkIndex) => (
                                    <ListItemLink key={link.title} {...link} exact={link.exact}/>
                                ))}
                            </List>
                        </div>
                    );
                })}
            </Drawer>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                {props.children}
            </main>
        </div>
    );
};

const linkSections = [
    {
        title: null, links: [
            {primary: 'Home', to: urls.home.main, icon: <MailIcon/>, exact: true},
        ]
    },
    {
        title: "Content", links: [
            {primary: 'Pages', to: urls.content.pages.main, icon: <MailIcon/>, exact: false},
        ]
    }
]

export default AuthenticatedLayout;
