import axios from 'axios';
const baseUrl = "https://api.cms.mightyhive.xyz"
export async function getPagesList(currentUser) {
    const url = `${baseUrl}/page/list`
    console.log("Getting Pages")

    return new Promise(async (resolve, reject) => {
        axios.get(url)
        .then((result) => {
            console.log(result)
            resolve(result.data.pages)
        })
        .catch((error) => {
            console.log(error)
            reject(error)
        })
    });
}

export async function createNewPage(pageUrl, title, contentBody) {
    const url = `${baseUrl}/page/create`
    console.log("Getting Pages")

    return new Promise(async (resolve, reject) => {
        axios.post(url, {
            pageUrl,
            title,
            contentBody

        })
            .then((result) => {
                console.log(result)
                resolve(result)
            })
            .catch((error) => {
                console.log(error)
                reject(error)
            })
    });
}

export async function getPageContent(pageUrl) {
    const url = `${baseUrl}/page/get?pageUrl=${pageUrl}`
    console.log("Getting Pages")

    return new Promise(async (resolve, reject) => {
        axios.get(url)
            .then((result) => {
                console.log(result)
                resolve(result.data)
            })
            .catch((error) => {
                console.log(error)
                reject(error)
            })
    });
}

export async function deletePage(pageUrl) {
    const url = `${baseUrl}/page/delete`
    console.log("Getting Pages")

    return new Promise(async (resolve, reject) => {
        axios.delete(url, {data:{pageUrl: pageUrl}})
            .then((result) => {
                console.log(result)
                resolve(result.data)
            })
            .catch((error) => {
                console.log(error)
                reject(error)
            })
    });
}

export async function uploadImage(image) {
    const url = `${baseUrl}/image/upload`
    console.log("Uploading Image")
    console.log(image)
    const formData = new FormData();
    formData.append('image', image)
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    console.log(Object.keys(formData))
    return new Promise(async (resolve, reject) => {
        axios.post(url, formData,config)
            .then((result) => {
                console.log(result)
                resolve(result)
            })
            .catch((error) => {
                console.log(error)
                reject(error)
            })
    });
}