import {useAuth} from "../auth/AuthContext";
import {Route, Redirect} from 'react-router-dom'
import {appRequiresVerification} from "./routeUtils";
export default function UnprotectedRoute({component: Component, ...rest}) {
    const {currentUser} = useAuth();
    return(
        <Route
            {...rest}
            render={(props) => {
                if (currentUser == null) {
                    return (
                        <Component {...props}/>
                    );
                }
                else {
                    if (!appRequiresVerification() || currentUser.emailVerified) {
                        return (
                            <Redirect to={{pathname: "/", state: {from: props.location}}}/>
                        );
                    }
                    else {
                        return (
                            <Redirect to={{pathname: "/", state: {from: props.location}}}/>
                        );
                    }
                }
            }}/>
    );
}
