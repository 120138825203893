import {useAuth} from "../auth/AuthContext";
import {Route, Redirect} from 'react-router-dom'
import {appRequiresVerification} from "./routeUtils";
export default function UnvalidateUserRoute({component: Component, ...rest}) {
    const {currentUser} = useAuth();
    return(
        <Route
            {...rest}
            render={(props) => {
                if (currentUser != null) {
                    return (
                        appRequiresVerification() && !currentUser.emailVerified ?
                            <Component {...props}/> :
                            <Redirect to={{pathname: "/", state: {from: props.location}}}/>
                    );
                }
                else {
                    return (
                        <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
                    );
                }
            }}/>
    );
}
