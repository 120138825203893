import React from 'react';
import {useAuth} from "../auth/AuthContext";
import Button from '@material-ui/core/Button';

const Dashboard = () => {
    const {logout} = useAuth();
    return (
        <div>
            <h1>Dashboard Coming Soon!</h1>
        </div>
    );
};

export default Dashboard;
