export const urls = {
    home: {main: "/"},
    content: {
        pages: {
            main: "/pages",
            edit: "/pages/edit",
            create: "/pages/create"
        }
    },
    auth: {
        login: {
            main: "/login"
        },
        register: {
            main: "/register"
        },
        validate: {
            main: "/validate"
        }
    }
}
