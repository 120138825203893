import React from 'react';
import {useAuth} from "../../auth/AuthContext";
import Button from "@material-ui/core/Button";

const ValidatePage = () => {
    const {currentUser, logout} = useAuth();

    return (
        <div>
            Please validate your account
            <Button color={'primary'} variant={'contained'} onClick={logout}>Logout</Button>
            <Button color={'primary'} variant={'contained'} onClick={() => {currentUser.sendEmailVerification()}}>Verify</Button>

        </div>
    );
};

export default ValidatePage;
