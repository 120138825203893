import {useAuth} from "../auth/AuthContext";
import {Route, Redirect} from 'react-router-dom'
import {appRequiresVerification} from "./routeUtils";
export default function ProtectedRoute({component: Component, ...rest}) {
    const {currentUser} = useAuth();
        return(
            <Route
                {...rest}
                render={(props) => {
                    console.log(props)
                if (currentUser != null) {
                    if(appRequiresVerification() && !currentUser.emailVerified) {
                        return <Redirect to={{pathname: "/validate", state: {from: props.location}}}/>
                    }
                    return (
                        <Component {...props} {...rest}/>
                    );
                }
                else {
                    return (
                        <Redirect to={{pathname: "/login", state: {from: props.location}}}/>
                    );
                }
            }}/>
        );
}
