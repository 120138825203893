import React, {useState} from 'react';
import {useAuth} from "./auth/AuthContext";
import ProtectedRoute from "./routes/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import UnprotectedRoute from "./routes/UnprotectedRoute";
import {Switch, Route} from 'react-router-dom';
import LoginPage from "./pages/authPages/LoginPage";
import {CircularProgress} from "@material-ui/core";
import UnvalidateUserRoute from "./routes/UnvalidatedUserRoute";
import ValidatePage from "./pages/authPages/ValidatePage";
import MainAppRoute from "./routes/MainAppRoute";
import AuthenticatedLayout from "./layouts/AuthenticatedLayout";
import MemberListPage from "./pages/members/MemberListPage";
import TimesheetListPage from "./pages/timesheets/TimesheetListPage";
import TimesheetTemplateListPage from "./pages/timesheets/TimesheetTemplateListPage";
import TimesheetModule from "./pages/modules/TimesheetModule";
import MembersModule from "./pages/modules/MembersModule";
import DashboardModule from "./pages/modules/DashboardModule";
import {urls} from "./routes/urls";
import PagesListPage from "./pages/contentPages/PagesListPage";
import PagesEditPage from "./pages/contentPages/PagesEditPage";
import {ToastProvider} from "./components/ToastContext";

const Root = (props) => {
    const {isInitialised} = useAuth();
    return (
        <div>
            { isInitialised ? <InitialisedLayout/>: <UninitialisedLayout/> }
        </div>
    );
};

const InitialisedLayout = () => {
    const {currentUser} = useAuth();
    const [topBarActions, setTopBarActions] = useState(null);
    return(
        <ToastProvider>
            <div className="App">
                {
                    currentUser != null ?
                        <AuthenticatedLayout actionBar={topBarActions}>
                            <AppRouteSwitch setActionBar={setTopBarActions}/>
                        </AuthenticatedLayout> :
                        <AppRouteSwitch setActionBar={setTopBarActions}/>
                }
            </div>
        </ToastProvider>
    );
};


const AppRouteSwitch = (props) => {
    console.log("App Switch")
    return(
        <Switch>
            <ProtectedRoute exact path={urls.home.main} component={DashboardModule} renderTopBarActions={props.setActionBar}/>
            <ProtectedRoute path={urls.content.pages.main} component={PagesListPage} renderTopBarActions={props.setActionBar}/>
            <UnprotectedRoute exact path={urls.auth.login.main} component={LoginPage}/>
            <UnvalidateUserRoute exact path={urls.auth.validate.main} component={ValidatePage}/>
            <Route component={() => {return <div>Page Not Found</div>}}/>
        </Switch>
    );
}
const UninitialisedLayout = () => {
    return(
        <div style={{width: 'calc(100vw)', height: "calc(100vh)", display: 'flex', justifyContent: 'center', alignItems: "center"}}>
            <CircularProgress/>
        </div>
    );
};
export default Root;
