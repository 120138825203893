import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";

const portraitUrl = 'https://verba.com.au/wp-content/uploads/2019/03/portrait-square-10.jpg'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

function createData(img, name, ga, type, status) {
    return { img, name, ga, type, status };
}

const rows = [
    createData(portraitUrl, 'Campbell Brobbel', "2020106377", "7 Day", "Active"),
    createData(portraitUrl, 'Rhys Ellwood', "2020106378", "6 Day", "Active"),
    createData(portraitUrl, 'James Terry', "2020106379", "6 Day", "Suspended"),
    createData(portraitUrl, 'Kit Fong', "2020106380", "Lifestyle", "Disabled"),
    createData(portraitUrl, 'Andrew Gorrie', "2020106381", "7 Day", "Active"),
];

const MemberListPage = () => {
    const classes = useStyles();

    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell width={0}></TableCell>
                        <TableCell>Member Name</TableCell>
                        <TableCell align="right">GA #</TableCell>
                        <TableCell align="right">Member Type</TableCell>
                        <TableCell align="right">Account Status</TableCell>
                        <TableCell align="right">Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                <Avatar src={portraitUrl}/>
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.ga}</TableCell>
                            <TableCell align="right">{row.type}</TableCell>
                            <TableCell align="right"><StatusChip status={row.status}/></TableCell>
                            <TableCell align="right">Actions Here</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const StatusChip = ({status}) => {
    let backgroundColor = "green";
    let textColor = "white"
    switch(status) {
        case "Active":
            break
        case "Suspended":
            backgroundColor = "#fdd835"
            textColor = "black"
            break
        case "Disabled":
            backgroundColor = "#e53935"
            textColor = "white"
            break
    }
    return <Chip size="small" label={status} style={{backgroundColor: backgroundColor, color: textColor}}/>
}
export default MemberListPage;
