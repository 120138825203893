import logo from './logo.svg';
import './App.css';
import React from 'react';
import {BrowserRouter as Router, Switch} from "react-router-dom";

import { makeStyles, useTheme, createMuiTheme, ThemeProvider} from '@material-ui/core/styles';

import LoginPage from "./pages/authPages/LoginPage";
import {AuthProvider} from "./auth/AuthContext";
import ProtectedRoute from "./routes/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import UnprotectedRoute from "./routes/UnprotectedRoute";
import Root from "./Root";


const t = createMuiTheme({
    palette: {
        primary: {
            main: '#001249'
        }
    },
    typography: {
        fontFamily: 'Raleway'
    }
})

function App() {

    return (
        <Router>
                <ThemeProvider theme={t}>
                    <AuthProvider>
                        <Root/>
                    </AuthProvider>
                </ThemeProvider>
        </Router>
    );
}

export default App;
