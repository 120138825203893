import React, {createContext, useContext, useEffect, useState} from 'react';
import {auth,googleAuthProvider} from "../firebase/fire";

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [currentUser, setCurrentUser] = useState();
    const [isInitialised, setIsInitialised] = useState(false);
    const [loginError, setLoginError] = useState(null);

    function signup(email, password) {
        return auth.createUserWithEmailAndPassword(email, password);
    };

    function login(email, password) {
        return auth.signInWithEmailAndPassword(email, password);
    };

    function loginWithGoogle() {
        return new Promise(async (resolve, reject) => {
            auth.signInWithPopup(googleAuthProvider)
                .then(async (user) => {
                    console.log(user)
                    if(!user.user.email.includes("@mightyhive.com")) {
                        await user.user.delete();
                        await auth.signOut();
                        reject({message: "Email address is not a MightyHive email."})
                    }
                    else {
                        resolve(user)
                    }
                }).catch((error) => {
                    reject(error)
            })
        })
    };

    function logout() {
        return auth.signOut();
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            console.log(user)
            if(user && !user.email.includes("@mightyhive.com")) {
                setCurrentUser(null);
            }
            else {
                setCurrentUser(user);
            }
            setIsInitialised(true);
        });
        return unsubscribe;
    }, []);

    return (
        <AuthContext.Provider value={{currentUser, isInitialised, signup, login, loginWithGoogle, logout}}>
            {children}
        </AuthContext.Provider>
    );
};
