import React, {createContext, useContext, useEffect, useState} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

const ToastContext = createContext();

export function useToast() {
    return useContext(ToastContext);
}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export function ToastProvider({children}) {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState("");
    const [severity, setSeverity] = useState("success")
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsOpen(false);
    };

    function displaySuccessToast(message) {
        setMessage(message)
        setSeverity("success")
        setIsOpen(true)
    }

    function displayErrorToast(message) {
        setMessage(message)
        setSeverity("error")
        setIsOpen(true)
    }
    return (
        <ToastContext.Provider value={{displaySuccessToast, displayErrorToast}}>
            {children}
            <Snackbar open={isOpen} autoHideDuration={5000} onClose={handleClose}>
                <Alert onClose={handleClose} severity={severity}>
                    {message}
                </Alert>
            </Snackbar>
        </ToastContext.Provider>
    );
};
