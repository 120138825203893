import React, {useEffect} from 'react';
import {Switch} from 'react-router-dom'
import ProtectedRoute from "../../routes/ProtectedRoute";
import {Button} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import Dashboard from "../Dashboard";
import {urls} from "../../routes/urls";

const TopBarActions = () => {
    const TopBarActionLink = (props) => {return <NavLink {...props} activeStyle={{backgroundColor: 'green'}}/>}
    return(
        <div>

        </div>
    );
}

const DashboardModule = ({renderTopBarActions}) => {
    useEffect(() => {
        renderTopBarActions(TopBarActions)
    }, [])

    return (
        <Switch>
            <ProtectedRoute exact path={urls.home.main} component={Dashboard}/>
        </Switch>
    );
};

export default DashboardModule;
