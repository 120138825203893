import React, {useEffect, useState} from 'react';
import {getPagesList, deletePage} from '../../api/storage';
import {useAuth} from "../../auth/AuthContext";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {NavLink} from "react-router-dom";
import {Button} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {urls} from "../../routes/urls";
import {Switch, Route} from 'react-router-dom';
import PagesEditPage from "./PagesEditPage";
import PagesCreatePage from "./PagesCreatePage";
import ProtectedRoute from "../../routes/ProtectedRoute";
import {useToast} from "../../components/ToastContext";

const TopBarActions = () => {
    const TopBarActionLink = (props) => {return <NavLink {...props}/>}
    return(
        <div>
            <Button variant={'contained'} color={'light'} component={TopBarActionLink} to={urls.content.pages.create} exact style={{marginRight: 10}}>Add New Page</Button>
        </div>
    );
}

const PagesListPage = ({renderTopBarActions}) => {
    let [pages, setPages] = useState([]);
    const {displaySuccessToast, displayErrorToast} = useToast()
    async function downloadPages() {
        try {
            let pages = await getPagesList()
            setPages(pages)
        }
        catch(e) {
            console.log(e.message)
        }
    }
    useEffect(async () => {
        await downloadPages()
        renderTopBarActions(TopBarActions)
    }, [])

    async function handleDelete(url) {
        try {
            await deletePage(url)
            displaySuccessToast('Successfully deleted page.')
            await downloadPages()
        }
        catch(e) {
            displayErrorToast(e.message)
        }

    }
    async function handleCreatePage() {
        await downloadPages()
    }
    const PageList = () => {
        return(<div>
            <h1>Pages</h1>
            <TableContainer component={Paper}>
                <Table  aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Page URL</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pages.map((page) => (
                            <TableRow key={page.pageUrl}>
                                <TableCell component="th" scope="row">
                                    {page.pageUrl}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <Button variant={'contained'} color={'primary'} endIcon={<EditIcon/>} component={NavLink} to={`${urls.content.pages.edit}?pageUrl=${page.pageUrl}`}>Edit</Button>
                                    <Button variant={'contained'} style={{backgroundColor: "#f44336", color: 'white', marginLeft: 10}} onClick={() => handleDelete(page.pageUrl)} endIcon={<DeleteIcon/>}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>);
    }
    return (
        <Switch>
            <ProtectedRoute exact path={'/pages'} component={PageList}/>
            <ProtectedRoute exact path={'/pages/edit'} component={PagesEditPage} mode={'edit'}/>
            <ProtectedRoute exact path={'/pages/create'} onCreate={handleCreatePage} component={PagesEditPage} mode={'create'}/>
        </Switch>
    );
};

export default PagesListPage;